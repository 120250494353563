<template>
  <div class="good-item" :class="{ 'is-mobile': isMobile }" @click="handleShowDetail">
    <bg-style class="goods" :bg="model.goodsBackground" :radius="model.goodsBorderRadius">
      <im-image v-if="isHasCorner" :src="good.corner" class="corner-logo" is-lazy :scroll-container="lazyScrollContainer" />
      <bg-style class="thumb" :bg="model.logoBackground">
        <im-image :src="good.icon" class="relative good-logo" is-lazy :scroll-container="lazyScrollContainer" />
      </bg-style>
      <div class="name relative" :style="getColorStyle(model.goodsTitleColor, model.goodsTitleColorShow)">{{ goodName }}</div>
      <div v-if="isShowOriginalPrice" class="origin-price relative">{{ originalPriceText }}</div>
      <div v-if="hasGift" class="gifts relative">
        <div v-for="(item, index) in good.gift_list" :key="index" class="gift-item">
          <img :src="item.gift_goods_logo" alt="">
          <i class="el-icon-close" />
          <div class="gift-item__num">{{ item.num }}</div>
        </div>
      </div>
      <div class="goods-buy relative" :class="{ 'disabled': isShowCountdown || isSaleOut }">
        <shop-cart v-if="model.shopCartEnabled" class="shop-cart" :good="good" :disabled="editing" :model="model" :is-mobile="isMobile" />
        <bg-style class="buy" :bg="model.buyBackground" @click.native.stop="handleBuy">
          <div v-if="isShowCountdown" class="countdown relative">
            <span class="day">{{ countdownDays }} <em>Day</em></span>
            <span>{{ countdownHours }}:{{ countdownMinutes }}:{{ countdownSeconds }}</span>
          </div>
          <span v-else class="relative" :style="getColorStyle(model.goodsPriceColor, model.goodsPriceColorShow)">
            {{ realPriceText }}
          </span>
        </bg-style>
      </div>
    </bg-style>
  </div>
</template>

<script>

import imImage from "~/components/im30/image/index.vue";
import goodMixins from "~/site/widgets/storeWidget/oneGood/goodMixins";
import shopCart from "~/site/widgets/storeWidget/oneGood/shopCart.vue";

export default {
  name: 'OneGood',
  components: {
    shopCart,
    imImage
  },
  mixins: [goodMixins],
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    isDisabled() {
      return this.isShowCountdown || this.isSaleOut
    }
  },
  methods: {
    handleShowDetail() {
      if (this.isDisabled) return
      this.$emit('show-detail')
    },
    handleBuy() {
      if (this.isDisabled) return
      this.$emit('buy', this.good)
    }
  }
}
</script>

<style lang="less">
.good-item {
  width: 280px;
  height: auto;
  margin: 12px 9px;
  &.is-mobile {
    width: 165px;
    margin: 8px 6px;
    .goods {
      .thumb {
        height: 142px;
      }
      .corner-logo {
        width: 62px;
        height: 62px;
        left: -3px;
        top: -3px;
      }
      .origin-price {
        font-size: 10px;
        margin-top: 6px;
      }
      .name {
        font-size: 12px;
        margin-top: 10px;
      }
      .gifts {
        .gift-item {
          font-size: 10px;
          margin-right: 12px;
          img {
            width: 14px;
            height: 14px;
          }
        }
      }
      &-buy {
        padding: 12px;
        .shop-cart {
          margin-right: 6px;
        }
        .buy {
          line-height: 26px;
          font-size: 12px;
          .countdown {
            font-size: 10px;
          }
        }
      }
    }
  }
}
.goods {
  position: relative;
  cursor: pointer;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .06);
  .thumb {
    width: 100%;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    .good-logo {
      transition: 0.24s ease all;
    }
    &:hover {
      .good-logo {
        transform: scale(1.05) translateY(-4px);
      }
    }
  }
  .corner-logo {
    position: absolute;
    left: -4px;
    top: -5px;
    width: 97px;
    height: 97px;
    z-index: 10;
  }
  .name {
    font-size: 18px;
    text-align: center;
    margin-top: 16px;
  }
  .origin-price {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    text-decoration: line-through;
    margin-top: 16px;
    color: var(--price-opacity-color);
  }
  .gifts {
    display: flex;
    width: fit-content;
    margin: 0 auto;
    padding-top: 10px;
    .gift-item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      font-size: 16px;
      color: var(--price-opacity-color);
      img {
        width: 24px;
        height: 24px;
        margin-right: 3px;
      }
    }
  }
  .goods-buy {
    padding: 16px 24px 24px 24px;
    display: flex;
    &.disabled {
      opacity: 0.36;
      cursor: not-allowed;
    }
    .shop-cart {
      flex-shrink: 0;
      margin-right: 12px;
    }
    .buy {
      line-height: 44px;
      width: 100%;
      text-align: center;
      font-size: 14px;
      .countdown {
        position: relative;
        font-size: 16px;
        z-index: 10;
        .day {
          border-right: 1px solid var(--text-secondary-color);
          padding-right: 6px;
          margin-right: 6px;
          em {
            color: var(--text-secondary-color);
            font-style: normal;
          }
        }
      }
    }
  }
}
</style>
