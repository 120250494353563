<template>
  <portal to="destination">
    <!-- 登录弹窗 -->
    <transition name="el-fade-in-linear">
      <bg-style
        v-if="loginShow"
        :class="['login-modal', !isMobile ? 'login-widget-wrap_desk' : editing ? 'login-widget-wrap_mobile' : 'login-widget-wrap_mobile_site']"
        :bg="model"
      >
        <div class="model-wrapper">
          <bg-style
            class="model-wrap overflow-y-auto"
            :style="{...modelWrapBorder, ...loginModalBgImg}"
            :bg="model.loginBackground"
          >
            <div v-if="isMobile" class="w-[32px] h-[32px] bg-[#E5E6EB] absolute z-10 right-5 top-5 rounded-full flex items-center justify-center">
              <i class="el-icon-close text-18 text-black" @click="handleMobileClose" />
            </div>
            <div
              v-if="!(isLogined && isMobile)"
              class="flex items-center justify-between relative"
              :style="{color: model.titleTextStyle.backgroundColor || ''}"
            >
              <span class="text-[36px] leading-[50px] font-[500]">{{ loginModalTitle }}</span>
              <span v-if="!isMobile" class="cursor-pointer" @click="handleClose">
                <im-icon class="text-[28px] cursor-pointer" icon="icon-clear" />
              </span>
            </div>
            <div
              v-if="isLogined && isMobile"
              class="flex items-center rounded-[4px] px-[16px] py-[17px] relative"
              :style="userInfoStyle"
            >
              <div
                class="w-[48px] h-[48px] rounded-[24px] mr-[12px] flex items-center justify-center logined_avator"
              >
                <im-icon class="text-[20px] user-icon" icon="icon-a-Login" />
              </div>
              <div
                class="flex-1 text-16 leading-6 break-all"
              >{{ siteLoginUserInfo.login_type === '1' ? (siteLoginUserInfo.player?.name || siteLoginUserInfo?.nickname) : (siteLoginUserInfo.email || '') }}</div>
              <div
                class="px-[8px] py-[4px] rounded-full ml-[14px] cursor-pointer text-14 logined_avator"
                :style="exitBtnStyle"
                @click="loginOut"
              >{{ $t('siteBuild.header.exit')}}</div>
            </div>
            <div class="content">
              <site-alert
                v-if="showAlert"
                :message="alertMessage.message"
                :type="alertMessage && alertMessage.type"
                style="margin-bottom: 32px;"
                @close="hideAlertMethod"
              />
              <div v-if="isShowNormalForm" class="login-forms-wrap">
                <site-alert
                  v-show="resisterSeconds"
                  message
                  type="success"
                  :show-close="false"
                  style="margin-bottom: 32px;"
                >
                  {{$t('siteBuild.header.registerSuccess')}}，
                  <Button
                    type="text"
                    @click="goToBindUserRoleMethod"
                  >{{$t('siteBuild.header.goto') + $t('siteBuild.header.bindUserAccount')}}</Button>
                  （{{resisterSeconds}}s）！
                </site-alert>
                <!-- 登录表单 -->
                <login-form
                  v-if="loginState === LoginStateEnum.LOGIN"
                  :device="device"
                  :model="model"
                  :editing="editing"
                  :login-loading="loginLoading"
                  :site-id="siteId"
                  @show-alert="showAlertMethod"
                  @login="loginMethod"
                ></login-form>
                <!-- 注册表单 -->
                <register-form
                  v-else-if="loginState === LoginStateEnum.REGISTER"
                  class="register-form"
                  :device="device"
                  :model="model"
                  :editing="editing"
                  :login-loading="loginLoading"
                  :site-id="siteId"
                  @show-alert="showAlertMethod"
                  @login="loginMethod"
                ></register-form>
                <!-- 忘记密码表单 -->
                <Forget-password
                  v-else-if="loginState === LoginStateEnum.FORGET_PASSWORD"
                  :device="device"
                  :model="model"
                  :editing="editing"
                  :login-loading="loginLoading"
                  :site-id="siteId"
                  @show-alert="showAlertMethod"
                ></Forget-password>
                <div class="flex items-center justify-center">
                  <Button
                    v-show="loginState !== LoginStateEnum.LOGIN"
                    :style="!isMobile ? newSubBtnDeskColor : newSubBtnMobileColor"
                    :type="!isMobile ? 'text' : 'default'"
                    :class="['back_btn', {'w-full h-[48px] mt-[24px]' : isMobile}]"
                    @click="()=>handleBackLogin()"
                  >{{$t('siteBuild.header.backLogin')}}</Button>
                </div>
              </div>
              <!-- 绑定游戏 ID表单 @bindUserInfoClick="bindUserInfoClick" -->
              <BindUserForm
                v-else
                :id.sync="id"
                :model="model"
                :device="device"
                :checking="checking"
                :logining="logining"
                :checked="checked"
                :editing="editing"
                :is-bind-user-login="isBindUserLogin"
                :curr-user-info="currUserInfo"
                @clear="clear"
                @checkUser="checkUser"
                @bindLogin="bindLogin"
                @show-checking="showChecking"
                @hide-checking="hideChecking"
              />
            </div>
          </bg-style>
        </div>
      </bg-style>
    </transition>
    <!-- 绑定游戏 ID的帮助信息弹窗 -->
    <!-- <BindUserInfo
      v-if="bindUserInfoVisible"
      key="bind-user-info"
      :visible.sync="bindUserInfoVisible"
      :editing="editing"
      :model="model"
    />-->
  </portal>
</template>

<script>
import { mapState } from 'vuex'
import colorMixin from './components/colorMixins'
import ForgetPassword from './components/forgetPassword'
import LoginForm from './components/loginForm'
import RegisterForm from './components/registerForm'
// import BindUserInfo from './components/bindUserInfo.vue'
import BindUserForm from './components/bindUserForm.vue'
import { useLoginState, LoginStateEnum } from './components/useLogin'
import Bus from '~/site/model/bus'
import Button from '~/site/components/forms/button'
import SiteAlert from '~/site/components/forms/alert'
import ImIcon from '~/components/common/ImIcon'
import EventTrackerMixins from '~/mixins/eventTracker'

const { getLoginState } = useLoginState()

export default {
  name: 'LoginWidget',
  components: {
    ImIcon,
    SiteAlert,
    ForgetPassword,
    LoginForm,
    RegisterForm,
    Button,
    // BindUserInfo,
    BindUserForm
  },
  mixins: [colorMixin, EventTrackerMixins],
  props: {
    model: {
      type: Object,
      default() {
        return {}
      },
    },
    editing: {
      type: Boolean,
      default: false,
    },
    device: {
      type: String,
      default: 'desktop'
    },
  },
  data() {
    const { setLoginState, handleBackLogin } = useLoginState()
    return {
      id: '', // 用户角色ID
      resisterSeconds: 0,
      logining: false, // 登录中
      checked: false, // 是否检查用户角色ID
      checking: false, // 检查中
      showCar: false, // 是否显示购物车
      infoShow: false, //  是否现示用户详情菜单
      infoModel: false, //  是否现示用户详情
      isHover: false,
      navShow: false,
      showAlert: false,
      loginLoading: false,
      alertMessage: {
        type: '',
        message: '',
      },
      LoginStateEnum,
      setLoginState,
      handleBackLogin,
      currUserInfo: {}, // 当前待绑定用户角色信息
      // bindUserInfoVisible: false,
    }
  },
  computed: {
    ...mapState({
      siteLoginUserInfo: (state) => state.user.siteLoginUserInfo,
      isSiteBindUser: (state) => state.user.isSiteBindUser, // 该变量直接穿透正常登录绑定 player_id的流程，直接呼出绑定 id弹窗
      userInfo: (state) => state.user.siteUserInfo || {},
      loginShow: (state) => state.user.loginShow || false, // 显示登录弹窗
      projectInfo: (state) => state.project.info,
    }),
    siteId() {
      return this.projectInfo?.id
    },
    loginState() {
      return getLoginState()
    },
    isLogined() {
      return this.editing ? false : !!(this.siteLoginUserInfo)
    },
    loginModalTitle() {
      if (this.siteLoginUserInfo && this.siteLoginUserInfo.id) {
        return this.$t('siteBuild.header.bindUserAccount')
      }
      const loginsState = this.loginState
      let title = this.$t('siteBuild.header.login')
      switch (loginsState) {
        case LoginStateEnum.LOGIN:
          title = this.$t('siteBuild.header.login')
          break
        case LoginStateEnum.REGISTER:
          title = this.$t('siteBuild.header.registerButton')
          break
        case LoginStateEnum.FORGET_PASSWORD:
          title = this.$t('siteBuild.header.findBackPassword')
          break
        case LoginStateEnum.LOGIN_SUCCESS:
          title = this.$t('siteBuild.header.login')
          break
        default:
          title = this.$t('siteBuild.header.bindUserAccount')
      }
      return title
    },
    modelWrapBorder() {
      let bg;
      if (this.siteLoginUserInfo) bg = this.model.loginBackground;
      if (!this.siteLoginUserInfo) bg = this.model.loginBackground;
      if (typeof bg?.backgroundBlur === 'number' && this.device === 'desktop') {
        return {
          borderRadius: bg?.backgroundBlur + 'px'
        }
      }
      return {};
    },
    isBindUserLogin() {
      return this.isSiteBindUser || this.siteLoginUserInfo?.login_type === '1';
    },
    /**
     * 该计算属性控制渲染哪种表单
     * true: 渲染正常的登录、注册、忘记密码的表单
     * false: 渲染绑定 player_id的表单
     */
    isShowNormalForm() {
      return (!this.siteLoginUserInfo || this.resisterSeconds > 0) && !this.isBindUserLogin
    },
    // 登录弹窗背景图片
    loginModalBgImg() {
      const isDesk = this.device === 'desktop'
      const customBgImg = isDesk ? this.model.pcModalBgImg : this.model.mbModalBgImg
      const bgImage = customBgImg.backgroundImage;
      const bgImageShow = customBgImg.backgroundImageShow;
      if (customBgImg.value && bgImage && bgImageShow) {
        return {
          backgroundImage: bgImage ? `url(${bgImage})` : 'none',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundColor: '#fff'
        }
      }
      return {}
    },
    isMobile() {
      return this.device === 'mobile'
    },
  },
  watch: {
    loginShow(val) {
      if (val && !this.editing) {
        this.setLoginShow()
      }
    }
  },
  methods: {
    handleMobileClose() {
      Bus.$emit('closeMobileLoginModal')
    },
    /**
     * @description: 关闭登录弹窗
     */
    handleClose() {
      if (this.editing) return
      this.clear(false)
      this.showAlert = false
      this.$store.commit('user/SET_LOGINSHOW', false)
      this.$store.commit('user/SET_SITE_BIND_USER', false)
      // this.$logger.captureMessage('点击关闭登录弹窗')
    },
    goToBindUserRoleMethod() {
      this.resisterSeconds = 0
      if (this.__timerId) {
        clearInterval(this.__timerId)
      }
    },
    // 显示隐藏 alert
    showAlertMethod(message = '', type = 'success') {
      this.alertMessage = {
        type,
        message,
      }
      this.showAlert = true
      setTimeout(() => {
        this.showAlert = false
      }, 5000)
    },
    hideAlertMethod() {
      this.showAlert = true
    },
    alertRegisterMethod() {
      this.resisterSeconds = 5
      this.__timerId = setInterval(() => {
        --this.resisterSeconds
        if (this.resisterSeconds === 0) {
          clearInterval(this.__timerId)
        }
      }, 1000)
    },
    getUserInfo(email) {
      this.$api.siteBuild
        .getUserInfo()
        .then((res) => {
          // this.$logger.setUser({})
          // this.$logger.captureMessage(email + '登录后获取用户信息成功')
          this.$utils.setSiteAuthInfo(this, res)
          if (res.player && res.player_id) {
            this.id = res.player_id
            this.currUserInfo = res.player || {}
            this.$store.commit('user/SET_SITEUSER', { ...res.player, uid: res.player_id })
            this.checked = true
            this.handleClose()
          }
          Bus.$emit('reloadGood', 'login')
          Bus.$emit('reloadActivity')
          Bus.$emit('closeLoginModal')

          // 添加游客登录功能后，/user接口中增加 login_type字段用于表示是哪种登录方式
          // '1': 游客登录， '2': email登录
          // 如果没有 login_type，则认为 token无效，需要重新登录
          if (!res.login_type) {
            this.loginOut();
          }
        })
        .catch((err) => {
          // this.$logger.captureMessage(email + '登录后获取用户信息失败')
          this.showAlertMethod(err, 'error')
          this.$utils.siteUserLoginOut(this)
        })
    },

    /**
     * 注册之后开始登录或直接登录的处理函数
     *
     * @param {*} data
     */
    loginMethod(data) {
      if (this.editing) return
      if (!data) return
      const { email, password } = data
      this.loginLoading = true
      this.$api.siteBuild
        .login({
          email,
          password,
          site_id: this.siteId
        })
        .then((result) => {
          this.$utils.setSiteToken(this, result.token)
          this.getUserInfo(email)
          this.eventTracker(3)
        })
        .catch((err) => {
          this.showAlertMethod(err, 'error')
        })
        .finally(() => {
          this.loginLoading = false
        })
    },

    /**
     * 为商城用户绑定 player_id
     * 或者直接使用 player_id进行游客登录
     */
    bindLogin() {
      if (this.isBindUserLogin) {
        this.bindUserDirectly();
      } else {
        this.bindUserAfterLogin();
      }
    },
    showChecking() {
      this.checking = true
    },
    hideChecking() {
      this.checking = false
    },

    /**
     * 无登录状态下，直接通过 player_id进行登录（即游客模式）
     */
    bindUserDirectly() {
      this.$api.siteBuild
        .loginWithPlayerId({
          site_id: this.siteId,
          player_id: this.id.trim(),
        })
        .then((data) => {
          const { user, token } = data
          // this.$logger.captureMessage('绑定角色【 ' + this.id + '】成功')
          this.$utils.setSiteAuthInfo(this, user)
          this.$utils.setSiteToken(this, token)
          this.$store.commit('user/BIND_PLAYER_INFO', user)
          this.$store.commit('user/UPDATE_UUID', this.id || new Date().valueOf())
          this.getUserInfo(user.email);
          this.handleClose()
          this.eventTracker(5)
        })
        .catch((error) => {
          this.showAlertMethod(error || 'error', 'error')
        })
        .finally(() => {
          this.logining = false
        })
    },

    /**
     * 登录状态下，为没有绑定 player_id的用户绑定该 id
     */
    bindUserAfterLogin() {
      if (this.editing) {
        return
      }
      if (!this.checked) return
      this.logining = true
      let tempUserInfo = { logined: true }
      tempUserInfo = Object.assign(tempUserInfo, this.currUserInfo, { uid: this.id.trim() })
      const playerId = tempUserInfo.uid || tempUserInfo.id
      // this.$logger.captureMessage('绑定角色中')
      this.$api.siteBuild
        .bindPlayer({
          player_id: (tempUserInfo.uid || tempUserInfo.id).trim()
        })
        .then(() => {
          // this.$logger.captureMessage('绑定角色【 ' + playerId + '】成功')
          this.$store.commit('user/SET_SITEUSER', tempUserInfo)

          Bus.$emit('reloadGood', 'login')
          Bus.$emit('reloadActivity')
          Bus.$emit('closeLoginModal')
          this.handleClose()
          this.eventTracker(5)
        })
        .catch((error) => {
          this.showAlertMethod(error || 'error', 'error')
        })
        .finally(() => {
          this.$store.commit('user/BIND_PLAYER_INFO', tempUserInfo)
          this.$store.commit('user/UPDATE_UUID', playerId || new Date().valueOf())
          this.logining = false
        })
    },

    /**
     * @description: 退出登录
     */
    loginOut() {
      if (this.editing) return
      this.checked = false
      this.id = ''
      this.currUserInfo = {}
      this.infoShow = false
      this.$utils.siteUserLoginOut(this)
      // this.$cookies.remove('siteInfo')
    },

    /**
     * @description: 检查用户角色ID
     */
    checkUser() {
      if (this.editing) {
        return
      }
      const playerId = this.id.trim()
      // console.log(playerId)
      if (!playerId) {
        return
      }
      this.checking = true
      // this.$logger.captureMessage('验证游戏id' + playerId + '中...')
      this.$api.siteBuild
        .getUserInfoByPlayerId(({
          project_id: this.projectInfo?.project_id,
          player_id: playerId
        }))
        .then((result) => {
          this.currUserInfo = result.user || {}
          // const tempUserInfo = result.user || {}
          // this.$logger.captureMessage('验证游戏id' + playerId + '成功')
          // Object.assign(tempUserInfo, params)
          this.checking = false
          this.checked = true
          // this.$store.commit('user/SET_SITEUSER', { ...tempUserInfo, uid })
        })
        .catch((err) => {
          // this.$logger.captureMessage('验证游戏id' + playerId + '失败')
          this.$imessage({
            content: err,
            type: 'warning',
          })
          this.checking = false
          // 未查到信息不允许绑定
          this.currUserInfo = {}
          this.checked = false
        })
    },

    /**
     * @description: 清空登录弹窗内容、检查、登录状态
     */
    clear(isLogger = true) {
      // isLogger && this.$logger.captureMessage('清除绑定角色')
      this.id = ''
      this.currUserInfo = {}
      this.checked = false
    },
    setLoginShow() {
      this.infoShow = false
      this.resisterSeconds = 0
      this.setLoginState(LoginStateEnum.LOGIN)
      const isLogin = !!this.$utils.getSiteToken(this)
      if (isLogin && this.userInfo?.uid) {
        this.id = this.userInfo.uid
        this.currUserInfo = this.userInfo
        this.checked = true
      } else {
        this.id = ''
        this.currUserInfo = {}
        this.checked = false
      }
      this.$store.commit('user/SET_LOGINSHOW', !this.editing)
    },
    setUserInfoShow() {
      if (!this.userInfo?.id) {
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        return
      }
      this.infoShow = true
    },

    // bindUserInfoClick() {
    //   this.bindUserInfoVisible = true
    // }
  },
}
</script>

<style lang="less" scoped>
.login-modal {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3001;
  color: #1f1d29;
  .model-wrapper {
    position: absolute;
    margin: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3021;
    width: fit-content;
    height: fit-content;

    .model-wrap {
      background: #fff;
    }
  }
}

.login-widget-wrap_desk {
  top: 0;
  .model-wrap {
    width: 530px;
    padding: 36px 50px;
    overflow: hidden;
  }
}

.login-widget-wrap_mobile {
  top: 60px;
  .model-wrapper {
    width: 100%;
    height: 100%;
    .model-wrap {
      width: 100%;
      height: 100%;
      padding: 36px 28px;
    }
  }

  .logined_avator {
    background: rgba(255, 255, 255, 0.12);
  }
}

.login-widget-wrap_mobile_site {
  @apply login-widget-wrap_mobile;

  position: fixed !important;
}
</style>
<style lang="less">
/* 登录表单 样式*/

.model-wrap {
  .site-input__inner {
    padding: 10px 16px;
    border-radius: 4px;
    box-sizing: border-box;
    color: #18171c !important;
    background-color: #fff !important;
    &::placeholder {
      color: #c9c9d4;
      font-size: 14px;
    }
    &:focus {
      outline: #5a47ee;
    }
    &.disabled {
      background: #f7f8fa;
      color: #c9c9d4;
    }

    // &:-webkit-autofill {
    //   box-shadow: 0 0 0px 1000px #f0f2f5 inset !important;
    // }
  }

  .site-form-item {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .site-button {
    font-size: 16px !important;
  }
}

.login-widget-wrap_desk {
  .site-input__inner {
    height: 46px;
    line-height: 46px;
  }

  .forget_btn,
  .back_btn {
    font-size: 14px !important;
  }
}

.login-widget-wrap_mobile {
  .site-input__inner {
    height: 48px;
    line-height: 48px;
  }
}

.login-widget-wrap_mobile_site {
  .site-input__inner {
    height: 48px;
    line-height: 48px;
  }
}
</style>
