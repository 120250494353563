// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".module-navigation{position:fixed;right:-20px;top:50%;transform:translate(-50%,-50%);z-index:1000}.module-navigation.is-edit{left:auto;right:220px}.module-navigation.is-edit.is-mobile{right:calc(var(--offset-left) - 45px)}.module-navigation__btn{align-items:center;background-color:#fff;border:1px solid #dedede;border-radius:100%;cursor:pointer;display:flex;height:40px;justify-content:center;width:40px}.module-navigation__content{border-radius:6px;overflow:hidden}.module-navigation__content .menu-list{padding:10px 0 0}.module-navigation__content .menu-list .item{align-items:center;cursor:pointer;display:flex;height:32px;overflow:hidden;padding:0 10px;white-space:nowrap}.module-navigation__content .menu-list .item.active{background-color:#3f54f2;color:#fff!important}.module-navigation__content .menu-list .item:hover{color:#3f54f2}.module-navigation__content .menu-list .item-title{max-width:100px;overflow:hidden;text-overflow:ellipsis}.module-navigation__popover{background-color:transparent;border:none;padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
