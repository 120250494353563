// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".coupon-page{background-color:#f0efef}.coupon-page__content{display:flex;margin:0 auto;max-width:1200px}.coupon-page__content .menu{flex-shrink:0;height:100%;width:200px}.coupon-page .list-area{grid-gap:15px;display:grid;gap:15px;grid-template-columns:repeat(2,1fr);height:calc(100vh - 160px);overflow:auto}.coupon-page.is-mobile .list-area{grid-template-columns:repeat(1,1fr)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
