<template>
  <div :class="isMobile ? 'quick_link_mobile' : 'quick_link_desk'">
    <div v-if="model.title" class="quick_link_title">
      <rich-text
        :value="model.title"
        theme="snow"
        :editing="editing"
        :disabled="!editing"
        :no-tool-bar="true"
        @onChangeText="titleChange"
      />
    </div>
    <div v-if="model.navs.length > 0" class="quick_link_navs flex-1 flex items-center flex-wrap">
      <div
        v-for="(linkItem,index) in model.navs"
        :key="index"
        class="quick_link_href"
        @click.stop="handleNavClick(linkItem)"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <a v-if="!editing" :alt="linkItem.url" href="javascript:void(0);" v-html="linkItem.label" />
        <template v-else>
          <rich-text
            :value="linkItem.label"
            :editing="editing"
            :disabled="!editing"
            theme="snow"
            :no-tool-bar="true"
            @onChangeText="(text) => {linkItem.label = text}"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { FooterQuickLinkWidgetModel } from '../../model'
import RichText from "~/components/richText";
import {navTo} from "~/utils";

export default {
  name: "FooterQuickLinkWidget",
  components: {
    RichText
  },
  props: {
    model: {
      type: Object,
      default() {
        return new FooterQuickLinkWidgetModel()
      }
    },
    editing: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleNavClick(item) {
      if (this.editing) return
      navTo(this, item)
    },
    gotoPage(url) {
      url && this.SitePage.to(url)
    },
    titleChange(text) {
      this.model.title = text
      this.model.isTitleEdit = true
    }
  }
}
</script>

<style lang="less" scoped>
.quick_link_desk {
  display: flex;
  max-width: 800px;
  .quick_link_title {
    font-size: 14px;
    line-height: 22px;
    margin-right: 24px;
  }

  .quick_link_navs {
    row-gap: 16px;
    column-gap: 24px;
  }

  .quick_link_href {
    font-size: 14px;
    line-height: 22px;
  }
}
.quick_link_mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  .quick_link_title {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .quick_link_navs {
    row-gap: 16px;
    column-gap: 20px;
    justify-content: center;
  }

  .quick_link_href {
    font-size: 12px;
    line-height: 18px;
  }
}
</style>
