<template>
<!--  <SiteIndex v-if="model" :white-list="whiteList" :model="model"></SiteIndex>-->
  <SiteIndex v-if="!native && model" :white-list="[]" :model="model" />
  <site-shop-cart v-else-if="(native === PageNameEnum.SHOP_CART) && model" :model="model" />
</template>

<script>
import SiteIndex from "~/site/components/siteIndex"
import SiteCore, {checkLogin, getSiteBuilderDataByService, initPwa} from "~/site/core";
import { useAssets } from "~/hooks/useAssets";
import tools from '~/utils';
import langConfigMixins from "~/mixins/langConfigMixins";
import SiteShopCart from "~/site/components/shopCartPage/index.vue";
import {PageNameEnum} from "~/enums/pageEnum";

export default {
  name: "SiteIndexPage",
  components: {
    SiteShopCart,
    SiteIndex
  },
  mixins: [langConfigMixins],
  layout: 'defaultLayout',
  async asyncData(options) {
    const isProd = true
    return await getSiteBuilderDataByService({ ...options, isProd })
  },
  data() {
    return {
      model: null,
      lang: {},
      pLang: '',
      native: null,
      mid: '',
      web: {},
      pid: '',
      domain: '',
      siteBuildId: '',
      langList: [],
      pageList: [],
      siteInfo: {
        default_language: '',
        published_conf: {}
      },
      baseInfo: {},
      languages: [],
      pwa: {}
    }
  },
  head() {
    if (!this.web.site) {
      return {}
    }
    return {
      title: this.web.site.seoSetting.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.web.site.seoSetting.desc
        },
      ],
      link: [
        {
          rel: 'icon',
          hid: 'favicon.ico',
          type: 'image/x-icon',
          href: this.web.site.seoSetting.favicon
        },
      ]
    }
  },
  computed: {
    PageNameEnum() {
      return PageNameEnum
    }
  },
  beforeDestroy() {
    this.pwa.off()
  },
  async mounted() {
    // 应用支持的语言列表
    const { setSiteLangList } = useAssets(this)
    setSiteLangList(this.langList)
    await this.setNewUrl()
    this.pwa = initPwa(this)
  },
  methods: {
    // 设置语言
    async setNewUrl() {
      const params = tools.getNeedParamsFormRouteParams(this.$route.params)
      this.native = params.native
      if (!params.lang && !params.native) {
        const sysLang = tools.getSysLanguage()
        const langArr = this.langList.map(item => item.code)
        const curLang = langArr.includes(sysLang) ? sysLang : this.siteInfo.default_language
        this.pLang = curLang
        const fullPath = `/${curLang}/`
        this.$nextTick(async () => {
          if (window) {
            const url = `${window.location.origin}${fullPath}`
            window.location.href = url
          } else {
            await this.$router.push(fullPath)
          }
        })
      } else {
        this.pLang = params.lang
        await this.init()
      }
      this.$cookies.set('lang', this.pLang)
      tools.setFavicon(this.web.site.seoSetting.favicon)
    },
    async getIpInfo() {
      const ipInfo = tools.getxfip(localStorage)
      const options = {
        headers: ipInfo,
      }
      const [err, result] = await this.$utils.to(this.$api.common.getIpInfo({}, options))
      if (!err) {
        this.$store.commit('user/SET_IPINFO', result)
      } else {
        // eslint-disable-next-line no-console
        console.log('获取ip报错：', err)
      }
    },
    async getCurrencies() {
      const res = await this.$api.common.getCurrencies()
      const map = {}
      res.data.forEach((c) => {
        map[c.id] = c
      })
      this.$store.commit('project/SET_CURRENCYINFO', {
        currency: res.data,
        currencieMap: map,
      })
    },
    // 初始化页面
    async init() {
      await checkLogin(this)
      await this.getCurrencies()
      await this.getIpInfo()
      const lang = this.pLang || this.siteInfo.default_language;
      const langIdData = await this.getLangConfig(lang, this.siteInfo.default_language, this.pid)
      this.model = new SiteCore({
        editMode: false,
        siteInfo: this.siteInfo.published_conf,
        baseInfo: this.baseInfo,
        languages: this.languages,
        langList: this.langList,
        lang,
        langIdData,
        pageList: this.pageList,
        isProd: true
      })
      tools.setPageTitle(this.web.site.seoSetting, langIdData)
      this.$store.commit('locale/SET_LANG', this.pLang || this.siteInfo.default_language)
      this.$store.commit('locale/SET_LANG_DATA', langIdData)
    }
  }
}
</script>

<style scoped></style>
