<template>
  <el-dialog class="lottery-history" :width="$store.getters.isMobile ? '100%' : '520px'" :visible.sync="visible" title="抽奖记录">
    <div class="lottery-history__content">
      <div class="list">
        <div v-for="(item, index) in list" :key="index" class="item flex items-center justify-between">
          <div class="flex items-center">
            <span class="mr-6">{{ index + 1 }}</span>
            <img class="w-[40px] h-[40px]" :src="item.gift_goods_logo" alt="">
            <i class="el-icon-close mx-2"></i>
            <span>{{ item.num }}</span>
          </div>
          <div class="time">
            <span class="ml-2">{{ item.lottery_time }}</span>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from "element-ui";
import {formatZoneTime} from "~/utils";

export default {
  components: {
    [Dialog.name]: Dialog
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      list: [],
      visible: false
    }
  },
  methods: {
    async init() {
      await this.getHistory()
      this.visible = true
    },
    async getHistory() {
      const params = {
        activity_id: this.model.bizId,
        page_no: 1,
        page_size: 1000
      }
      const [err, res] = await this.$utils.to(this.$api.activity.historyList(params))
      if (!err && res.list) {
        this.list = res.list.map(item => {
          const newItem = {...item}
          newItem.lottery_time = formatZoneTime(newItem.lottery_time * 1000).text
          return newItem
        })
      }
    }
  }
}
</script>


<style lang="less">
.lottery-history {
  .el-dialog__header {
    font-weight: bold;
    text-align: center;
    .el-dialog__title {
      font-size: 18px;
    }
  }
  &__content {
    .list {
      max-height: 400px;
      overflow-y: auto;
      .item {
        background-color: #F6F6F6;
        margin-bottom: 8px;
        padding: 12px;
        font-size: 12px;
      }
    }
  }
}
</style>
